<template>
  <div>
    <div v-if="isLoading">
      <base-spinner></base-spinner>
    </div>
    <div v-else class="card container special mt-5">
      <div class="row justify-content-center">
        <div class="col">
          <router-view></router-view>
        </div>
        <div class="card-title">
          <h1>Rental Details</h1>
        </div>

        <div v-if="selectedRental.videoId" class="row">
        <iframe
          class="col-12 col-sm-offset-3"
          width="720"
          height="576"
          :src="'https://www.youtube.com/embed/' + selectedRental.videoId"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
        </div>

        <div
          class="col-sm-6 col-md-4 m-1"
          v-for="image in bunnyImageUrls"
          :key="image"
        >
          <img :src="image" alt="" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-md-4 py-0">
          <div class="card-body">
            <!-- <p>
              Owner: {{ ownerName }}
            </p>
            <p>
              ownerPhoneNumber: {{ ownerPhoneNumber }}
            </p> -->
            <p class="card-title">
              <span class="fst-itali">{{ location }}, Kampala</span>
            </p>
            <p class="card-text">
              <span class="fw-normal">{{ description }}</span>
            </p>
            <p>
              <strong>{{ price }} UGX</strong> / month
            </p>
            <p>
              Contact us on

              <strong>
                <i class="fa-brands fa-whatsapp"></i>
                <a href="https://wa.me/+256751568755/?text=Hello"
                  >+256 751568755</a
                >
                <br /><a href="tel: +256788380658">+256 788380658</a>
              </strong>
            </p>
            <router-link v-if="!route" :to="BookLink" class="btn btn-primary"
              >Send a request</router-link
            >
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: ["id"],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    price() {
      return this.selectedRental.price;
    },
    bunnyImageUrls() {
      return this.selectedRental.bunnyImageUrls;
    },
    location() {
      return this.selectedRental.location;
    },
    description() {
      return this.selectedRental.description;
    },
    ownerName() {
      return this.selectedRental.ownerName
    },
    ownerPhoneNumber() {
      return this.selectedRental.ownerPhoneNumber
    },
    BookLink() {
      return this.$route.path + "/contact";
    },
    route() {
      const checkroute = this.$route.path.slice(-7);
      // console.log(checkroute);
      return checkroute == "contact";
    },
  },
  created() {
    const rentId = this.$route.params;
    // console.log("this is it", rentId.id, "this is the id");
    // console.log(this.selectedRental);
    this.selectedRental = this.$store.getters["rentals/properties"].find(
      (property) => property.id === rentId.id
    );
    // console.log("What is wrong?", this.selectedRental);

    // console.log(this.selectedRental.id)
  },
};
</script>

<style scoped>
div .imagePart {
  width: 100%;
  height: 20rem;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.special {
  margin-top: 3rem;
}
span {
  font-weight: bold;
}
#email {
  font-weight: bold;
}
</style>